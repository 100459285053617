<template>
    <div class="container page">
      <van-nav-bar title="충전" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
        <template #right>
          <span class="nav-right" @click="$router.push({path:'/rechargeRecord'})">충전기록</span>
        </template>
      </van-nav-bar>
      <div class="main">
        <div class="withdrawMoney">
          <span>충전금액</span>
          <div class="money">
            <div class="moneyNumber">
              <!-- <span class="moneyType">원</span> -->
              <van-field v-model="money" type="number" style="margin-left: 15px;" />
            </div>
            
          </div>
          <div class="information">
            <div class="description" v-if="false">
              <van-popover v-model="showPopover" trigger="click">
                <div class="popover-body" style="padding: 10px;">
                  <p>1.최소충전금액:10000</p>
                  <p>2.충전시간:출금시간</p></div>
                <template #reference @click="introduce()">
                  <van-icon name="info-o" />
                  충전설명
                </template>
              </van-popover>
            </div>
  
            <div class="balance" style="margin-top: 20px;">
              <span>잔액：</span>
              <span class="number">{{ this.userInfo.money }}</span>
            </div>
          </div>
        </div>
        <van-button class="withdraw_btn" type="default" @click="doRecharge()">확인</van-button>
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showPopover: false,
        money:"",
        userInfo:{},
        rule:{
            min:500,
            max: 10000000,
            start:'8:00',
            end: '18:00'
        }
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
      getUserInfo(){
        this.$http({
          method: 'get',
          url: 'user_info'
        }).then(res=>{
          if(res.code === 200){
            this.userInfo = res.data;
            this.name = res.data.name;
          }else if(res.code ===401){
            this.$toast(res.msg);
          }
        })
      },
      
      doRecharge(){
        if(this.money <= 0){
          this.$toast("충전금액 입력");
          return false;
        }else {
          this.$http({
            method: 'post',
            data:{money:this.money},
            url: 'user_recharge'
          }).then(res=>{
            if(res.code === 200){
              location. reload()
              this.$toast(res.msg);
              this.getUserInfo();
            }else if(res.code ===401){
              this.$toast(res.msg);
            }
          })
        }
      },
      introduce(){
        this.showPopover = true;
      }
    },
    created() {
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.getUserInfo();
      }
    }
  };
  </script>
  
  <style lang='less' scoped>
  @import "../../assets/css/base.css";
  .van-cell {
    font-size: 35px;
    line-height: 80px;
  }
  .container p{
    padding: 0 15px;
    margin-top: 15px;
    font-size: 30px;
    color: #dc2037;
  }
  .container .main{
    display: flex;
    flex-direction: column;
    background-color: #f2f2f5;
    height: calc(100% - 50px);
    position: relative;
  }
  .container .main .withdrawMoney{
    display: flex;
    flex-direction: column;
    color: #000;
    padding: 0 20px;
    white-space: nowrap;
    font-size: 35px;
    background-color: #fff;
  }
  .container .main .withdrawMoney span {
    padding: 10px 0;
  }
  .container .main .withdrawMoney .money{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f5;
  }
  .container .main .withdrawMoney .money .moneyNumber{
    font-size: 50px;
    display: flex;
    flex-direction: row;
  }
  .container .main .withdrawMoney span {
    padding: 10px 0;
  }
  .container .main .withdrawMoney .money .all{
    color: #d10404;
  }
  .container .main .withdrawMoney .money .moneyNumber .van-cell {
    font-size: 50px;
    padding: 0!important;
  }
  .container .main .withdrawMoney .information{
    padding-bottom: 30px;
  }
  .container .main .withdrawMoney .information .description {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0;
  }
  .container .main .withdrawMoney span{
    padding: 10px 0;
  }
  .container .main .withdrawMoney .information .balance .number{
    color: #d10404;
  }
  .withdraw_btn {
    margin: 20px 30px 0;
    height: 80px;
    line-height: 1.22667rem;
    border-radius: 50px;
    color: #fff;
    font-size: 30px;
    font-weight: bolder;
    border: none;
    background: linear-gradient(
        90deg,#e6c3a1,#7e5678);
  }
  </style>
  
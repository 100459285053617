<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="파트너선택" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="지역별">
					<div class="card">해피앤딩 | 지역 서비스 | 안전한 파트너</div>
					
                    <div class="address">
                        <van-grid :column-num="3" :gutter="10">
                            <van-grid-item @click="addgo(v)" v-for="(v, k) in addlist" :key="k">
                                <van-image class="game_item_img" :src="v.img">
                                    <template v-slot:loading>
                                        <van-loading type="circular" />
                                    </template>
                                </van-image>
                                <span class="rig-name">{{ v.name }}</span>
                            </van-grid-item>
                        </van-grid>
                    </div>
				</van-tab>
				<van-tab title="질문답변">
					<div class="card">만남 가능한 여성 파트너는 어떤 분들이 있나요?</div>
					<div class="rig-box">
						<p class="rig-title">스폰서 지원 가능한 특급 고객님들을 위하여 중매</p>
						<p class="rig-content">안녕하세요. 알짜배기들만 원하시고 경제능력 되시는 분들은 주목 해주세요. 최소 기간 한달부터 가능 하구요. 알짜배기 대학생 제일 이쁜 아가씨만 중매 해드리립니다. 해당 사항 조건은 상담해주시면 됩니다.</p>
						<p class="rig-title">서비스 가능 지역</p>
						<p class="rig-content">전국 모든 도시에서 만남이 가능하며, 일부 도서지역은 예약을 통하여 만남이 가능합니다.</p>
						<p class="rig-title">만남 가능한 여성 파트너는 어떤 분들이 있나요?</p>
						<p class="rig-content">모델, 승무원, 대학생, 직장인 등 다양한 여성분과 만남이 가능하며, 오직 저희 플랫폼을 통해서만만남이 가능합니다.</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
                console.log(this.addlist);
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
body{
    background-color: #000 !important;
}
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
    background-color: #000;
}
.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 0px;
	background: #f2f2f5;
    background: #000;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs{
    background-color: #000;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
     color: #fff;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
